import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Container from "react-bootstrap/Container";
import { useForm } from "react-hook-form";
import { forgotPwd } from "../Services/AppService";
import { toast } from "react-toastify";

function Forgotpass() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      const postForgetPwd = await forgotPwd(data);

      if (postForgetPwd.code === 500) {
        toast.error(postForgetPwd.message);
        reset();
      } else {
        toast.success(postForgetPwd.message);
        reset();
      }
    } catch (error) {
      console.error("Error during API request:", error);

      if (error.response && error.response.data) {
        const errorMessage =
          error.response.data.message || "An error occurred!!";
        toast.error(errorMessage);
      } else {
        toast.error("An error occurred!!");
      }
      reset();
    }
  };

  return (
    <>
      <Header />
      <div className="signup-upper">
        <div className="inner-signup-wrapper">
          <Container>
            <div className="upper-fotgot-clas">
              <div className="inner-sgnup">
                <h5>Having Trouble Signing-In</h5>
                <p className="reset-pass-p">
                  Enter your email id so you can receive a link on your mail.
                </p>

                <div className="signup-form">
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group
                      className="input-signup-main"
                      controlId="formGroupEmail">
                      <Form.Label>Email ID</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter your Email Address"
                        {...register("email", {
                          required: "Email is required",
                        })}
                      />
                      <Form.Text className="text-danger">
                        {errors.email?.message}
                      </Form.Text>
                    </Form.Group>

                    <Button className="btn-create-acc" type="submit">
                      Reset Password
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Forgotpass;
