import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Container from "react-bootstrap/Container";
import { useForm } from "react-hook-form";
import { contentHome, logInUser, verifyMail } from "../Services/AppService";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import ReactLoading from "react-loading";

function Login() {
  const [isLoading, setIsLoading] = useState(null);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const location = useLocation();
  const urlParams = new URLSearchParams(location?.search);
  const verificationToken = urlParams.get("verification_token");
  const [isChecked, setIsChecked] = useState(true);
  const storedData = JSON.parse(localStorage.getItem("logIndata"));
  const [loginImageUrl, setLoginImageUrl] = useState("");

  const title = "Sign-In";

  useEffect(() => {
    localStorage.removeItem("token");
    if (isChecked && storedData) {
      setValue("email", storedData.email || "");
      setValue("password", storedData.password || "");
    }
  }, [isChecked, storedData, setValue]);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (verificationToken) {
        try {
          const emailVerificationResponse = await verifyMail({
            verification_token: verificationToken,
          });

          if (emailVerificationResponse.code === 200) {
            toast.success(emailVerificationResponse?.message);
          } else {
            toast.error(emailVerificationResponse?.message);
          }
        } catch (error) {
          console.error("Error during API call:", error);
          if (error.response && error.response.data) {
            const errorMessage =
              error.response.data.message ||
              "An error occurred while logging in";
            toast.error(errorMessage);
          } else {
            toast.error("An error occurred while logging in");
          }
        }
      }
    };

    fetchData();
  }, [verificationToken]);

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const postLogInData = await logInUser(data);
      if (postLogInData.code === 200) {
        toast.success(postLogInData.message);
        const token = postLogInData.token;
        localStorage.setItem("equi_token", token);

        if (isChecked) {
          localStorage.setItem("logIndata", JSON.stringify(data));
        } else {
          localStorage.removeItem("logIndata");
        }
        setTimeout(() => {
          setIsLoading(false);
        }, 3000);
        navigate("/updateaccount");
      } else {
        toast.error(postLogInData.message);
        setTimeout(() => {
          setIsLoading(false);
        }, 3000);
      }
    } catch (error) {
      console.error("Error during API request:", error);
      if (error.response && error.response.data) {
        const errorMessage =
          error.response.data.message || "An error occurred while logging in";
        toast.error(errorMessage);
        setTimeout(() => {
          setIsLoading(false);
        }, 3000);
      } else {
        toast.error("An error occurred while logging in");
        setTimeout(() => {
          setIsLoading(false);
        }, 3000);
      }
    }
  };
  const fetchResponse = async () => {
    try {
      const contentResponse = await contentHome();
      setLoginImageUrl(contentResponse?.data?.background_banner_image_login_url);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    } catch (error) {
      console.error("Error fetching home content:", error);
    }
  };

  useEffect(() => {
    fetchResponse();
  }, []);

  return (
    <>
      <Header title={title} />
      <div
        className="signup-upper"
        style={{ backgroundImage: `url(${loginImageUrl})` }}>
        <Container>
          <div className="upper-signup-clas">
            <div className="inner-sgnup custm-sign-in">
              <h5>{title}</h5>
              <p className="alredy-signup">
                New to Equi Source? <Link to="/signUp">Sign-Up</Link>
              </p>

              <div className="signup-form">
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Form.Group
                    className="input-signup-main"
                    controlId="formGroupEmail">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter your Email Address"
                      {...register("email", {
                        required: "Email is required",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Invalid email address",
                        },
                      })}
                    />
                    <Form.Text className="text-danger">
                      {errors.email?.message}
                    </Form.Text>
                  </Form.Group>
                  <Form.Group
                    className="input-signup-main"
                    controlId="formGroupPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder=" Enter Your Password"
                      {...register("password", {
                        required: "Password is required",
                        minLength: {
                          value: 8,
                          message:
                            "Password must be at least 8 characters long",
                        },
                        maxLength: {
                          value: 20,
                          message: "Password cannot exceed 20 characters",
                        },
                        uppercase: {
                          value: /[A-Z]/,
                          message: "At least one uppercase letter is required",
                        },
                        lowercase: {
                          value: /[a-z]/,
                          message: "At least one lowercase letter is required",
                        },
                        number: {
                          value: /\d/,
                          message: "At least one number is required",
                        },
                        specialChar: {
                          value: /[@$!%*?&]/,
                          message: "At least one special character is required",
                        },
                      })}
                    />
                    <Form.Text className="text-danger">
                      {errors.password?.message}
                    </Form.Text>
                  </Form.Group>
                  <Row className="trouble-name">
                    <div className="col-md-6">
                      <Form.Group className="remember-me" id="formGridCheckbox">
                        <Form.Check
                          type="checkbox"
                          label="Remember Me"
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Link to="/forgotpassword" className="have-trou">
                        Having trouble Signing-in?
                      </Link>
                    </div>
                  </Row>
                  {!isLoading ? (
                    <Button className="btn-create-acc" type="submit">
                      Sign In
                    </Button>
                  ) : (
                    <Button className="btn-create-acc-loader" type="submit">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "start",
                          justifyContent: "center",
                          marginBottom: "2px",
                        }}>
                        <ReactLoading
                          type={"spin"}
                          color={"#fff"}
                          height={20}
                          width={25}
                        />
                      </div>
                    </Button>
                  )}
                </Form>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default Login;