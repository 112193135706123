import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Headimg from "../Assets/images/header.png";
import { Link } from "react-router-dom";
import blankProfile from "../Assets/images/blank-profile-picture-973460_1280.png";
import { userAccount } from "../Services/AppService";
import { toast } from "react-toastify";


function Header({ title }) {


  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    address: "",
    phone: "",
    picture: "",
  });

  const token = localStorage.getItem("equi_token");

  const userDetailsResponse = async () => {
    try {
      const fetchResponse = await userAccount(token);
      if (fetchResponse.code === 200) {
        return setUserDetails(fetchResponse.data);
      }
    } catch (error) {
      console.error("Error during API request:", error);
      if (error.response && error.response.data) {
        const errorMessage =
          error.response.data.message ||
          "An error occurred while fetching data";
        toast.error(errorMessage);
      } else {
        toast.error("An error occurred while fetching data");
      }
    }
  };

  const handleClick = () => {
    const element = document.getElementById('gradeTopStallions');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (token) {
      userDetailsResponse();
    }
  }, []);

  return (
    <>
      <div>
        <Navbar expand="lg" className="header-bg-color">
          <Container>
            <Navbar.Brand>
              <Link to="/">
                <figure className="header-logo">
                  <img src={Headimg} />
                </figure>
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              <Nav
                className="ms-auto my-2 my-lg-0 nav-heading"
                style={{ maxHeight: "100px" }}
                navbarScroll>
                <Link to="/">Home</Link>
                <Link to="/"  onClick={handleClick}>Grades & Top Stallions</Link>
                <Link to="/subscription">Subscription</Link>
                <Link to="/reports">Breeding Reports</Link>
                {token ? (
                  <Link to="/updateaccount">
                    <div className="profile-img-header d-flex">
                      <figure className="header-profie">
                        <img
                          src={
                            userDetails?.picture
                              ? userDetails?.picture
                              : blankProfile
                          }
                          alt=""
                        />
                      </figure>
                      <div className="name-header-profile">
                        <p className="verified-name">{userDetails?.name}</p>
                        <p className="verified-user mb-0">Verified User</p>
                      </div>
                    </div>
                  </Link>
                ) : (
                  <Link
                    to={title === "Sign-In" ? "/signUp" : "/login"}
                    className="sign-register">
                    <i class="fa-regular fa-user" />
                    Sign-In / Register
                  </Link>
                )}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </>
  );
}

export default Header;
