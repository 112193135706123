import React, { useEffect, useState } from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import NoData from "../Assets/images/equinodata.png";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Sirerankingtable from "../Components/Sirerankingtable";
import Slider from "react-slick";
import { detailStallions, policy } from "../Services/AppService";
import { useParams } from "react-router-dom";
import { paymentUrl } from "../Services/AppService";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Detailpage = () => {
  const { stallionIndex } = useParams();
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [stallionsDetails, setStallionsDetails] = useState([]);
  const [crouselImage, setCrouselImage] = useState([]);
  const [accepted, setAccepted] = useState(false);
  const [openModal, setOpenModal] = useState({
    user_terms: false,
    privacy_policy: false,
    privacy_center: false,
  });
  const [policiesData, setPoliciesData] = useState();

  const handleClose = () => setOpenModal(false);

  const handleCheckboxChange = () => {
    setAccepted(!accepted);
  };

  const navigate = useNavigate();

  const token = localStorage.getItem("equi_token");

  const handleSubmit = async () => {
    if (accepted) {
      if (token) {
        try {
          const price_id = localStorage.getItem("price_id");
          const fetchResponse = await paymentUrl(
            price_id,
            stallionIndex,
            token
          );
          if (fetchResponse.code === 200) {
            window.open(fetchResponse.data.url);
          } else {
            toast.error(fetchResponse.message);
          }
        } catch (error) {
          console.error("Error during API call:", error);
          if (error?.response && error?.response?.data) {
            const errorMessage =
              error?.response?.data.message || "An error occurred! Retry later";
            toast.error(errorMessage);
          } else {
            toast.error("An error occurred! Retry later");
          }
        }
      } else {
        navigate("/login");
      }
    }
  };
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  }, [slider1, slider2]);

  const fetchStallionsDetails = async () => {
    try {
      const contentResponse = await detailStallions(stallionIndex);
      setStallionsDetails(contentResponse.data);
      setCrouselImage(contentResponse.data.media.split(","));
    } catch (error) {
      console.error("Error fetching content stallions:", error);
    }
  };

  const fetchPolicies = async () => {
    try {
      const fetchResponse = await policy();
      setPoliciesData(fetchResponse.data);
    } catch (error) {}
  };

  useEffect(() => {
    fetchStallionsDetails();
    fetchPolicies();
  }, []);

  const CustomPrevArrow = (props) => (
    <div className="custom-arrow custom-prev-arrow" onClick={props.onClick}>
      <i class="fa-solid fa-chevron-left"></i>
    </div>
  );

  const CustomNextArrow = (props) => (
    <div className="custom-arrow custom-next-arrow" onClick={props.onClick}>
      <i class="fa-solid fa-chevron-right"></i>
    </div>
  );

  const settings1 = {
    asNavFor: nav2,
    ref: (slider) => setSlider1(slider),
  };

  const settings2 = {
    asNavFor: nav1,
    ref: (slider) => setSlider2(slider),
    // slidesToShow: 4,
    swipeToSlide: true,
    focusOnSelect: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  console.log(crouselImage,"crouselImage")

  return (
    <>
      <Header />
      <div className="datail-uppersec">
        <Container>
          <Modal
            show={
              openModal.privacy_center ||
              openModal.privacy_policy ||
              openModal.user_terms
                ? true
                : false
            }
            onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                {openModal.privacy_center
                  ? policiesData?.privacy_center?.title
                  : openModal.privacy_policy
                  ? policiesData?.privacy_policy?.title
                  : policiesData?.user_terms?.title}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div
                dangerouslySetInnerHTML={
                  openModal.privacy_center
                    ? { __html: policiesData?.privacy_center?.description }
                    : openModal.privacy_policy
                    ? { __html: policiesData?.privacy_policy?.description }
                    : { __html: policiesData?.user_terms?.description }
                }
              />
            </Modal.Body>
          </Modal>
          <Row className="row-slider-accordion-wrapper">
            {!stallionsDetails?.title ||
            !stallionsDetails?.about ||
            !stallionsDetails?.location ||
            !stallionsDetails?.entry_price ? (
              <>
                <h5 className="heading-strike" style={{ textAlign: "center" }}>
                  {stallionsDetails?.name}
                </h5>
                <img src={NoData} height="400px" width="30%" alt=""/>
              </>
            ) : (
              <>
                <div className="col-md-5">
                  <div className="slider-detailpage">
                    <div>
                      {crouselImage.length !== 0 && (
                        <Slider {...settings1} asNavFor={nav2} >
                          {crouselImage.map((item, index) => (
                            <div className="slider-images"  key={index}>
                              <figure>
                                <img src={item} alt="img not found" />
                              </figure>
                            </div>
                          ))}
                        </Slider>
                      )}

                      {crouselImage.length > 1 && (
                        <Slider
                          {...settings2}
                          asNavFor={nav1}
                          slidesToShow={3}
                          swipeToSlide={true}
                          focusOnSelect={true}
                          style={{ width: "70%", margin: "auto" }}>
                          {crouselImage.map((item, index) => (
                            <div className="sec-same-img" key={index}>
                              <figure className="">
                                <img
                                  className="img-fluid"
                                  src={item}
                                  alt="img not found"
                                  
                                />
                              </figure>
                            </div>
                          ))}
                        </Slider>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="innnerright-sec">
                    <div className="inner-sec-border">
                      <h5 className="heading-strike">
                        {stallionsDetails?.name} - {stallionsDetails?.title}
                      </h5>
                      <p className="para-detail-page">
                      {stallionsDetails?.description ? stallionsDetails?.description : "No data found"}                      </p>
                      <p className="standind-clas mb-0">
                        <span>Standing at: </span>
                        {stallionsDetails?.location}
                      </p>
                    </div>
                    <div className="fees-accordion">
                      <p className="enter-fees">
                        Stud Fee:
                        <span> {`$${stallionsDetails?.entry_price}`}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )}
          </Row>
        </Container>

        <Container>
          <div className="leading-para">
            <h5>ABOUT</h5>
            <p>
            {stallionsDetails?.about}
            </p>
          </div>

          {/* table --------------------------------------- */}
          <div className="sire-table-clas">
            <h5>Sire Rankings</h5>
            {Object.keys(stallionsDetails).length ? (
              <Sirerankingtable stallionsDetails={stallionsDetails} />
            ) : (
              "No data found"
            )}
          </div>
          <div className="upper-detail-button text-left">
            <label className="terms-label">
              <Form.Group className="remember-me" id="formGridCheckbox">
                <Form.Check
                  type="checkbox"
                  label={
                    <>
                      By creating an account, you agree to our{" "}
                      <span
                        className="underline-and-bold"
                        onClick={() =>
                          setOpenModal((prev) => ({
                            ...prev,
                            user_terms: true,
                          }))
                        }>
                        User Terms Agreement{" "}
                      </span>
                      and{" "}
                      <span
                        className="underline-and-bold"
                        onClick={() =>
                          setOpenModal((prev) => ({
                            ...prev,
                            privacy_policy: true,
                          }))
                        }>
                        Privacy Policy.{" "}
                      </span>
                      {/* and{" "} */}
                      {/* <span
                        className="underline-and-bold"
                        onClick={() => setOpenModal(true)}>
                        SMS Notification Policy.{" "}
                      </span> */}
                      You can opt and update your privacy settings anytime by
                      visiting our{" "}
                      <span
                        className="underline-and-bold"
                        onClick={() =>
                          setOpenModal((prev) => ({
                            ...prev,
                            privacy_center: true,
                          }))
                        }>
                        Privacy Center.
                      </span>
                    </>
                  }
                  checked={accepted}
                  onChange={handleCheckboxChange}
                />
              </Form.Group>
            </label>
            <button
              disabled={!accepted}
              className={accepted ? "chart-btn" : "chart-btn-disabled"}
              onClick={handleSubmit}>
              Buy Stallion Subscription
            </button>
          </div>
        </Container>
      </div>

      {/*-------------------------- Footer ------------------------------------------------------- */}

      <Footer />
    </>
  );
};

export default Detailpage;
