import React from "react";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import { changePass } from "../Services/AppService";
import { toast } from "react-toastify";
import Form from "react-bootstrap/Form";

function Changepassword() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const token = localStorage.getItem("equi_token");

    try {
      const fetchResponse = await changePass(data, token);
      if (fetchResponse.code === 200) {
        toast.success(fetchResponse.message);
        reset();
      } else {
        toast.error(fetchResponse.message);
        reset();
      }
    } catch (error) {
      console.error("Error during API request:", error);

      if (error.response && error.response.data) {
        const errorMessage =
          error.response.data.message ||
          "An error occurred while changing password";
        toast.error(errorMessage);
      } else {
        toast.error("An error occurred while changing password");
      }

      reset();
    }
  };

  return (
    <>
      <div className="upper-right-sec-update">
        <h5 className="change-pass-main">Change Password</h5>
        <div className="wrapper-cgangepass">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group
              className="input-account-comn"
              controlId="formGroupPassword">
              <Form.Label>Current Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter Current Password"
                {...register("current_password", {
                  required: "Current Password is required",
                  minLength: {
                    value: 8,
                    message: "Password must be at least 8 characters long",
                  },
                  maxLength: {
                    value: 20,
                    message: "Password cannot exceed 20 characters",
                  },
                  pattern: {
                    value: /^(?=.*[a-z])/,
                    message: "At least one lowercase letter is required",
                  },
                  pattern: {
                    value: /^(?=.*[A-Z])/,
                    message: "At least one uppercase letter is required",
                  },
                  pattern: {
                    value: /^(?=.*\d)/,
                    message: "At least one number is required",
                  },
                  pattern: {
                    value: /^(?=.*[@$!%*?&])/,
                    message: "At least one special character is required",
                  },
                })}
              />
              <Form.Text className="text-danger">
                {errors.current_password?.message}
              </Form.Text>
            </Form.Group>

            <Form.Group
              className="input-account-comn marg-custm"
              controlId="formGroupPassword">
              <Form.Label>Create New Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter Create New Password"
                {...register("new_password", {
                  required: "New Password is required",
                  minLength: {
                    value: 8,
                    message: "Password must be at least 8 characters long",
                  },
                  maxLength: {
                    value: 20,
                    message: "Password cannot exceed 20 characters",
                  },
                  pattern: {
                    value: /^(?=.*[a-z])/,
                    message: "At least one lowercase letter is required",
                  },
                  pattern: {
                    value: /^(?=.*[A-Z])/,
                    message: "At least one uppercase letter is required",
                  },
                  pattern: {
                    value: /^(?=.*\d)/,
                    message: "At least one number is required",
                  },
                  pattern: {
                    value: /^(?=.*[@$!%*?&])/,
                    message: "At least one special character is required",
                  },
                })}
              />
              <Form.Text className="text-danger">
                {errors.new_password?.message}
              </Form.Text>
            </Form.Group>

            <Button className="change-pass-btn-main" type="submit">
              Change Password
            </Button>
          </Form>
        </div>
      </div>
    </>
  );
}

export default Changepassword;
