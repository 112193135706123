import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { updateProfileUser, userAccount } from "../Services/AppService";
import blankProfileImg from "../Assets/images/blank-profile-picture-973460_1280.png";
import { ReactSVG } from "react-svg";
import Tickpen from "../Assets/images/iconamoon_edit-light.svg";

function Editprofile({ userDetails, setUserDetails }) {
  const [isEdit, setIsEdit] = useState(false);

  const [profileImg, setProfileImg] = useState("");

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    address: "",
    phone: "",
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, touchedFields },
  } = useForm({mode: 'onSubmit',});

  useEffect(() => {
    if (isEdit) {
      setFormData({
        name: userDetails.name || "",
        email: userDetails.email || "",
        address: userDetails.address || "",
        phone: userDetails.phone || "",
      });
    }
  }, [setValue, formData, isEdit, userDetails]);


  const token = localStorage.getItem("equi_token");

  const userDetailsResponse = async () => {
    try {
      const fetchResponse = await userAccount(token);
      if (fetchResponse.code === 200) {
        return setUserDetails(fetchResponse.data);
      }
    } catch (error) {
      console.error("Error during API request:", error);
      if (error.response && error.response.data) {
        const errorMessage =
          error.response.data.message ||
          "An error occurred while fetching data";
        toast.error(errorMessage);
      } else {
        toast.error("An error occurred while fetching data");
      }
    }
  };

  const handleEdit = () => {
    setIsEdit(!isEdit);
  };

  useEffect(() => {
    userDetailsResponse();
  }, [isEdit]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];

      if (allowedTypes.includes(file.type)) {
        const reader = new FileReader();

        reader.onloadend = () => {
          const base64String = reader.result.split(",")[1];
          setProfileImg(base64String);
        };

        reader.readAsDataURL(file);
      } else {
        toast.error("Please select a valid image file (PNG, JPEG, or JPG).");
        event.target.value = null;
      }
    }
  };

  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append("profile_picture", profileImg);

      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });

      const fetchResponse = await updateProfileUser(formData, token);
      if (fetchResponse.code === 200) {
        toast.success(fetchResponse.message);
        setIsEdit(false);
      } else {
        toast.error(fetchResponse.message);
      }
    } catch (error) {
      console.error("Error during API request:", error);
      if (error.response && error.response.data) {
        const errorMessage =
          error.response.data.message ||
          "An error occurred while updating profile";
        toast.error(errorMessage);
      } else {
        toast.error("An error occurred while updating profile");
      }
    }
  };

  return (
    <>
      {isEdit && (
        <div className="upper-right-sec-update">
          <h5 className="change-pass-main">Update Profile</h5>
          <div className="wrapper-cgangepass">
            <div className="prfile-img-show">
              <figure>
                <img
                  src={
                    profileImg
                      ? `data:image/jpeg;base64,${profileImg}`
                      : userDetails.picture
                      ? userDetails.picture
                      : blankProfileImg
                  }
                  alt=""
                />
              </figure>
              <Form.Group
                controlId="formFileMultiple"
                className="file-upload-multiple">
                <Form.Label className="uploadFile">
                  <ReactSVG className="pen-svg" src={Tickpen} />
                  <span className="filename" />
                  <Form.Control
                    type="file"
                    className="dis-flile-pencil"
                    multiple
                    onChange={handleImageChange}
                  />
                </Form.Label>
              </Form.Group>
            </div>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group
                className="input-account-comn"
                controlId="formGroupname">
                <Form.Label>Full Name *</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Full Name"
                  {...register("name", {
                    required: "Name is required",
                    minLength: {
                      value: 3,
                      message: "Name must be at least 3 characters long",
                    },
                    pattern: {
                      value: /^[^\s].+[^\s]$/,
                      message: "Name cannot start or end with spaces",
                    },
                  })}
                  defaultValue={formData.name || ""}
                  onChange={(e) =>
                    setFormData((prevData) => ({
                      ...prevData,
                      name: e.target.value,
                    }))
                  }
                />
                <Form.Text className="text-danger">
                  {(touchedFields.name && errors.name?.message) || ""}
                </Form.Text>
              </Form.Group>
              <Form.Group
                className="input-account-comn"
                controlId="formGroupEmail">
                <Form.Label>Email Address *</Form.Label>
                <Form.Control
                  type="email"
                  placeholder={userDetails.email}
                  disabled
                />
              </Form.Group>
              <Form.Group
                className="input-account-comn"
                controlId="formGroupname">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Address"
                  {...register("address", {
                    // required: "Address is required",
                  })}
                  defaultValue={formData.address || ""}
                  onChange={(e) =>
                    setFormData((prevData) => ({
                      ...prevData,
                      address: e.target.value,
                    }))
                  }
                />
                {/* <Form.Text className="text-danger">
                  {(touchedFields.address && errors.address?.message) || ""}
                </Form.Text> */}
              </Form.Group>
              <Form.Group
                className="input-account-comn marg-custm"
                controlId="formGroupname">
                <Form.Label> Phone *</Form.Label>
                <Form.Control
                  type="tel"
                  placeholder="Enter Phone Number"
                  {...register("phone", {
                    required: "Phone is required",
                    pattern: {
                      value: /^[0-9]{10}$/,
                      message: "Please enter a valid 10-digit phone number",
                    },
                  })}
                  defaultValue={formData.phone || ""}
                  onChange={setValue()}
                  maxLength={10}
                  onKeyDown={(e) => {
                    const allowedKeys = [
                      "Backspace",
                      "ArrowLeft",
                      "ArrowRight",
                      "Delete",
                    ];
                    if (!/^\d$/.test(e.key) && !allowedKeys.includes(e.key)) {
                      e.preventDefault();
                    }
          
                  }}
                />
                <Form.Text className="text-danger">
                  {(touchedFields.phone && errors.phone?.message) || ""}
                </Form.Text>
              </Form.Group>

              <Button className="change-pass-btn-main" type="submit">
                Update Profile
              </Button>
            </Form>
          </div>
        </div>
      )}
      {!isEdit && (
        <div className="upper-right-sec-update my-profile">
          <h5 className="change-pass-main">My Profile</h5>
          <div className="wrapper-cgangepass">
            <div className="prfile-img-show">
              <figure>
                <img
                  src={
                    userDetails.picture ? userDetails.picture : blankProfileImg
                  }
                  alt=""
                />
              </figure>
            </div>

            <Form>
              <Form.Group
                className="input-account-comn"
                controlId="formGroupname">
                <Form.Label>Full Name *</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={userDetails.name}
                  disabled
                />
              </Form.Group>
              <Form.Group
                className="input-account-comn"
                controlId="formGroupEmail">
                <Form.Label>Email Address *</Form.Label>
                <Form.Control
                  type="email"
                  placeholder={userDetails.email}
                  disabled
                />
              </Form.Group>
              <Form.Group
                className="input-account-comn"
                controlId="formGroupname">
                <Form.Label>Address </Form.Label>
                <Form.Control
                  type="address"
                  placeholder={userDetails.address}
                  disabled
                />
              </Form.Group>
              <Form.Group
                className="input-account-comn marg-custm"
                controlId="formGroupname">
                <Form.Label>Phone *</Form.Label>
                <Form.Control
                  type="number"
                  placeholder={userDetails.phone}
                  disabled
                />
              </Form.Group>
              <Button
                className="change-pass-btn-main"
                onClick={() => handleEdit()}>
                Edit Profile
              </Button>
            </Form>
          </div>
        </div>
      )}
    </>
  );
}

export default Editprofile;
