import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import Updateaccount from './Updateaccount';

const Protected = (props) => {

    const {componenets} = props;
    const navigate = useNavigate();
    useEffect(()=>{

         let login = localStorage.getItem('equi_token')
         if(!login){
            navigate('/login')
         }
    },[])

  return (
    <div>
      <Updateaccount />
    </div>
  )
}

export default Protected
