import React, {useState} from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Ourplan from "../Components/Ourplan";

function Subscription() {


  const [dataPlan, setDataPlan] = useState("");


  return (
    <>
      <Header />

       <Ourplan setDataPlan={setDataPlan} dataPlan={dataPlan} />

      <Footer />
    </>
  );
}

export default Subscription;
