import React, { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import { contentStallions } from "../Services/AppService";

function Tabestable({
  setStallionIndex,
  setColStallionIndex,
  selectedKey,
  setSelectedKey,
}) {
  
  const [stallionsData, setStallionsData] = useState([]);
  const [isActive, setIsActive] = useState({});



  const fetchData = async (key) => {
    try {
      const contentResponse = await contentStallions(key);
      setStallionsData(contentResponse.data);
    } catch (error) {
      console.error("Error fetching content stallions:", error);
    }
  };


  useEffect(() => {
    fetchData(selectedKey);
  }, [selectedKey]);


  useEffect(() => {
    if (stallionsData?.length > 0) {
      const defaultStallionIndex = stallionsData[0]?.['A+'][0]?.id;
      setStallionIndex(defaultStallionIndex);
      const firstColumnIds = transposedData.map(
        (rowData) => rowData[Object.keys(rowData)[0]].id
      );
      // setColStallionIndex(firstColumnIds);
    }
  }, [stallionsData]);

  const handleNavClick = async (eventKey) => {
    setSelectedKey(eventKey);
    setIsActive({});
    setColStallionIndex([]);
    if (stallionsData?.length > 0) {
      const defaultStallionIndex = stallionsData[0]?.['A+'][0]?.id;
      setStallionIndex(defaultStallionIndex);

      const firstColumnIds = transposedData?.map(
        (rowData) => rowData[Object.keys(rowData)[0]].id
      );
      // setColStallionIndex(firstColumnIds);
    }
  };

  const transposedData = stallionsData?.reduce((acc, item) => {
    Object.keys(item).forEach((key) => {
      item[key].forEach((i, innerIndex) => {
        if (!acc[innerIndex]) {
          acc[innerIndex] = {};
        }
        acc[innerIndex][key] = i;
      });
    });
    return acc;
  }, []);

  const handleStallionIndex = (event, colIndex, rowIndex) => {
    const columnIds = transposedData?.map(
      (rowData) => rowData[Object.keys(rowData)[colIndex]].id
    );
    setColStallionIndex(columnIds);
    setStallionIndex(event);
    setIsActive({ row: rowIndex, col: colIndex });
  };

  return (
    <>
      <Tab.Container id="left-tabs-example" defaultActiveKey="s_index_1">
        <Row>
          <Col sm={12}>
            <Nav variant="pills" className="grades-table">
              {/* <Form.Select aria-label="Default select example">
                <option>Barrel Racing</option>
                <option value="1">Roping</option>
                <option value="2">Reining</option>
              </Form.Select> */}
              <Nav.Item>
                <Nav.Link
                  eventKey="s_index_1"
                  onClick={() => handleNavClick("s_index_1")}>
                  Sire Index
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="s_index_2"
                  onClick={() => handleNavClick("s_index_2")}>
                  Dams Sire Index
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="s_index_3"
                  onClick={() => handleNavClick("s_index_3")}>
                  2nd Dams Sire Index
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="s_index_4"
                  onClick={() => handleNavClick("s_index_4")}>
                   3rd Dams Sire Index
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={12}>
            <Tab.Content>
              <Tab.Pane eventKey={selectedKey}>
                <div className="main-table-home">
                  <table className="w-100">
                    <thead>
                      <tr>
                        {transposedData?.length > 0 ? (
                          Object?.keys(transposedData[0])?.map((key, index) => (
                            <th key={key} className={`bg${index + 1}`}>
                              {key}
                            </th>
                          ))
                        ) : (
                          <th>No data</th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="empty-space"></td>
                      </tr>
                      {transposedData?.length > 0 ? (
                        transposedData?.map((rowData, rowIndex) => (
                          <tr key={rowIndex}>
                            {Object.values(rowData).map((value, colIndex) => (
                              <td
                                key={colIndex}
                                onClick={() =>
                                  handleStallionIndex(
                                    value.id,
                                    colIndex,
                                    rowIndex
                                  )
                                }
                                className={
                                  isActive.row === rowIndex &&
                                  isActive.col === colIndex
                                    ? "active"
                                    : "not-active"
                                }>
                                {value?.name}
                              </td>
                            ))}
                          </tr>
                        ))
                      ) : (
                        <td>No data</td>
                      )}
                    </tbody>
                  </table>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </>
  );
}

export default Tabestable;
