import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { subscriptionStatus, reportSaveReport } from "../Services/AppService";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import Lotte1 from "../Assets/lotifile/data1.json";
import Lotte2 from "../Assets/lotifile/reject.json";

const SuccessPage = () => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location?.search);
  const session_id = urlParams.get("session_id");
  const stallion_id = urlParams.get("stallion_id");
  const token = localStorage.getItem("equi_token");
  const report_id = urlParams.get("report_id");
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(true); // Initialize loading as true

  let paramsBody = { session_id: session_id };

  const postResponse = async () => {
    if (stallion_id) {
      paramsBody.stallion_id = stallion_id;
    } else {
      paramsBody.report_id = report_id;
    }

    try {
      if (stallion_id) {
        const resultResponse = await subscriptionStatus(paramsBody, token);
        console.log("subscription", resultResponse);
        setResponse(resultResponse.code); // Set response code
        setLoading(false); // Set loading to false
        toast.message(resultResponse.message);
        return resultResponse;
      } else if (report_id) {
        const resultResponse = await reportSaveReport(paramsBody, token);
        console.log("report", resultResponse);
        setResponse(resultResponse.code); // Set response code
        setLoading(false); // Set loading to false
        toast.message(resultResponse.message);
        return resultResponse;
      }
    } catch (error) {
      // console.log("Error in saving subscription", error);
      setLoading(false); // Set loading to false
      if (error.response && error.response.data) {
        const errorMessage =
          error.response.data.message;
        toast.error(errorMessage);
      } else {
        // toast.error("An error occurred!!");
      }
    }
  };

  useEffect(() => {
    postResponse();
  }, []);

  console.log(response, "res");

  return (
    <>
      {loading ? (
        <section className="upper-success-payment1">
          <div className="main-inner-cont">
            <div className="inner-success">
              Do not close the window, your payment is still in progress...

            </div>
          </div>
        </section>
      )
        : (
          <section className="upper-success-payment1">
            <div className="main-inner-cont">
              <div className="inner-success">
                <div className="lotifile">
                  <Player
                    style={{ background: "transparent" }}
                    autoplay={true}
                    loop={true}
                    controls={false}
                    src={response === 200 ?Lotte1: Lotte2}
                    speed={0.8}
                  >
                    <Controls />
                  </Player>
                </div>
                {response === 200 ? (
                  <h4 className="payment-success-head1">Payment Success</h4>
                ) : (
                  <h4 className="payment-failed-head1">Payment Failed</h4>
                )}

                <div className="payment-type"></div>
                <div className="btn-success-pay">
                  <Link to="/home" className="btn-reject1-close">
                    Close
                  </Link>
                </div>
              </div>
            </div>
          </section>
        )}
    </>
  );
};

export default SuccessPage;
