import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Container from "react-bootstrap/Container";
import { useForm } from "react-hook-form";
import { resetPwd } from "../Services/AppService";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function Resetpass() {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const reset_token = urlParams.get("token");
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const { password, confirmPassword } = data;

    try {
     
      if (password === confirmPassword) {
        const resetPwdResponse = await resetPwd({ password, reset_token });
        toast.success(resetPwdResponse.message);
        navigate("/login");
      }else{
        toast.error("Passwords did not match")
      }
    } catch (error) {
      console.error("Error in API call:", error);

      if (error.response && error.response.data) {
        const errorMessage =
          error.response.data.message || "An error occurred while logging in";
        toast.error(errorMessage);
      } else {
        toast.error("An error occurred while logging in");
      }
    }
  };

  return (
    <>
      <Header />

      <div className="signup-upper ">
        <div className="inner-signup-wrapper">
          <Container>
            <div className="upper-reset-clas">
              <div className="inner-sgnup custm-reset-upper">
                <h5>Reset Password</h5>
                <p className="reset-pass-p">
                  Your new password must be different from previously used
                  password.
                </p>

                <div className="signup-form">
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group
                      className="input-signup-main"
                      controlId="formGroupPassword">
                      <Form.Label>New Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Password"
                        {...register("password", {
                          required: "New password required",
                          minLength: {
                            value: 8,
                            message:
                              "Password must be at least 8 characters long",
                          },
                          maxLength: {
                            value: 20,
                            message: "Password cannot exceed 20 characters",
                          },
                          pattern: {
                            value: /^(?=.*[a-z])/,
                            message:
                              "At least one lowercase letter is required",
                          },
                          pattern: {
                            value: /^(?=.*[A-Z])/,
                            message:
                              "At least one uppercase letter is required",
                          },
                          pattern: {
                            value: /^(?=.*\d)/,
                            message: "At least one number is required",
                          },
                          pattern: {
                            value: /^(?=.*[@$!%*?&])/,
                            message:
                              "At least one special character is required",
                          },
                        })}
                      />
                      <Form.Text className="text-danger">
                        {errors.password?.message}
                      </Form.Text>
                    </Form.Group>
                    <Form.Group
                      className="input-signup-main reset-pass"
                      controlId="formGroupPassword">
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Password"
                        {...register("confirmPassword", {
                          required: "Confirm password required",
                          minLength: {
                            value: 8,
                            message:
                              "Password must be at least 8 characters long",
                          },
                          maxLength: {
                            value: 20,
                            message: "Password cannot exceed 20 characters",
                          },
                          pattern: {
                            value: /^(?=.*[a-z])/,
                            message:
                              "At least one lowercase letter is required",
                          },
                          pattern: {
                            value: /^(?=.*[A-Z])/,
                            message:
                              "At least one uppercase letter is required",
                          },
                          pattern: {
                            value: /^(?=.*\d)/,
                            message: "At least one number is required",
                          },
                          pattern: {
                            value: /^(?=.*[@$!%*?&])/,
                            message:
                              "At least one special character is required",
                          },
                        })}
                      />
                      <Form.Text className="text-danger">
                        {errors.confirmPassword?.message}
                      </Form.Text>
                    </Form.Group>
                    <Button type="submit" className="btn-create-acc">
                      Reset Password
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Resetpass;
