import React, { useState} from 'react'
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Changepassword from '../Components/Changepassword';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Container from 'react-bootstrap/Container';
import { ReactSVG } from 'react-svg';
import profile from '../Assets/images/profile (2).svg'
import Changepass from '../Assets/images/changepass.svg'
import Subs from '../Assets/images/reward.svg'
import Logout from '../Assets/images/logout.svg'
import { useNavigate} from 'react-router-dom';

import Editprofile from '../Components/Editprofile';
import Subscriptionplan from '../Components/Subscriptionplan';
import ReportPlan from '../Components/ReportPlan';


function Updateaccount() {

  const navigate = useNavigate();

  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    address: "",
    phone: "",
    picture: "",
  });

  const handleLogout = () => { 
    localStorage.removeItem('equi_token');
    navigate('/login');
  };

  return (
    <>
       <div className="upper-update-acc">
        <div className="inner-upper-acc">
          <Header name={userDetails?.name} profile={userDetails?.picture}/>
          <div className="update-acc-page-banner">
            <h5>My account</h5>
          </div>
        </div>
      </div>

      <div className="tabs-main-update">
        <Container>
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
              <Col sm={4} className='right-small-acc-pill'>
                <div className="inner-pill-inner">
                  <p className='my-acc-mian'>My Account</p>
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="first"><ReactSVG className='rpofile-svg' src={profile} /> My Profile</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second"><ReactSVG className='rpofile-svg' src={Changepass} />Change Password</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third"><ReactSVG className='rpofile-svg' src={Subs} />My Subscription</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="fourth"><ReactSVG className='rpofile-svg' src={Subs} />My Reports</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link onClick={handleLogout}><ReactSVG className='rpofile-svg' src={Logout} />Logout</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
              </Col>
              <Col sm={8}>
                <Tab.Content>

                  {/* edit profile ------------------------------------------------------- */}

                  <Tab.Pane eventKey="first">
                    <Editprofile userDetails={userDetails} setUserDetails={setUserDetails} />
                  </Tab.Pane>


                  {/* change password --------------------------------------------------------------- */}

                  <Tab.Pane eventKey="second">
                    <Changepassword />
                  </Tab.Pane>

                  {/* Subscription ------------------------------------------------------------- */}
                  <Tab.Pane eventKey="third">
                   <Subscriptionplan />
                  </Tab.Pane>

                  {/* Reports ------------------------------------------------------------- */}
                  <Tab.Pane eventKey="fourth">
                   <ReportPlan />
                  </Tab.Pane>
               
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>



      </div>




      <Footer />
    
    </>
  )
}

export default Updateaccount