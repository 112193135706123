import React from 'react'
import Logofoot from '../Assets/images/footlogo.png'
import { Link } from 'react-router-dom'
function Footer() {
    return (
        <>
            <div className="footer">
                <div className="logo-foot">
                    <figure className='foot-logo-main'>
                        <img src={Logofoot} className='img-fluid' alt="" />
                    </figure>

                    <div className="footer-links">
                        <ul className='d-flex'>
                            <li> <Link to="/home">Home</Link></li>
                            <li> <Link to="/home">Grades & Top Stallions </Link></li>
                            <li><Link to="/subscription">Subscription </Link></li>
                        </ul>

                    </div>
                    <div className="footer-bottom">
                        <p className='mb-0'>©Copyright. All right Reserved 2023- EQUI Source</p>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Footer