import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Label,
} from "recharts";
import { Link } from "react-router-dom";
import { stallionChart } from "../Services/AppService";

const truncateTickLabel = (label, maxLength) => {
  if (label.length > maxLength) {
    return label.substring(0, maxLength) + "...";
  }
  return label;
};

function Charts({ stallionIndex, colStallionIndex, selectedKey }) {
  const [chartData, setChartData] = useState([]);
  const [indexScore, setIndexScore] = useState({
    index_score: null,
    name: "",
    grade: "",
  });

  localStorage.setItem("stallion_index", stallionIndex);

  const fetchData = async () => {
    try {
      const response = await stallionChart({
        stallion_id: stallionIndex,
        sort_by: selectedKey,
        s_ids:
          JSON.stringify(colStallionIndex) === "[]"
            ? null
            : JSON.stringify(colStallionIndex),
      });
      setChartData(response.data.chart);
      setIndexScore((prev) => ({
        ...prev,
        index_score: response.data.index_score,
        name: response.data.name,
        grade: response.data.x_axis_label,
      }));
      return;
    } catch (error) {
      console.error("Error fetching stallions chart:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [stallionIndex, selectedKey, colStallionIndex]);

  const tickLabels = (selectedKey) => {
    switch (selectedKey) {
      case "s_index_4":
        return "Index 4";
      case "s_index_3":
        return "Index 3";
      case "s_index_2":
        return "Index 2";
      case "s_index_1":
        return "Index 1";
      default:
        return selectedKey;
    }
  };

  return (
    <>
      <Container>
        <div className="charts-upper">
          <div className="row">
            <div className="col-md-4">
              <div className="index-score-upper">
                <h5 className="will-head">{indexScore?.name}</h5>
                <div className="inner-score">
                  <p className="mb-0">{indexScore?.index_score}</p>
                </div>
                <div className="index-sco-heading">
                  <p className="mb-0">Index Score</p>
                </div>
                <div className="chart-upper-btn">
                  <Link
                    to={`/detail/${stallionIndex}`}
                    className="chart-btn"
                    onClick={() => window.scrollTo(0, 0)}>
                    View the Stallion's Detail
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="upper-chart-main">
                <ResponsiveContainer width="100%" height={300}>
                  <ScatterChart
                    margin={{
                      top: 20,
                      right: 20,
                      bottom: 20,
                      left: 20,
                    }}>
                    <CartesianGrid
                      vertical={false}
                      strokeDasharray="3 0"
                      stroke="#00000012"
                    />
                    <XAxis
                      dataKey="name"
                      name="Name"
                      tickLine={false}
                      fill="#1C1C1C"
                      fontWeight={400}
                      fontSize={12}
                      interval={0}
                      tickCount={chartData?.length}
                      tick={false}>
                      <Label
                        value={
                          indexScore?.grade
                            ? `${tickLabels(selectedKey)} (Grade ${
                                indexScore?.grade
                              })`
                            : `${tickLabels(selectedKey)}`
                        }
                        position="insideCenter"
                        angle={0}
                        fill="#111"
                        fontWeight={500}
                        fontSize={14}
                        fontFamily="Inter"
                      />
                    </XAxis>
                    <YAxis
                      dataKey="rate"
                      name="Rate"
                      axisLine={false}
                      tickLine={false}
                      fill="#1C1C1C"
                      fontWeight={400}
                      fontSize={12}>
                      <Label
                        value="Rate"
                        position="insideLeft"
                        angle={-90}
                        fill="#111"
                        fontWeight={500}
                        fontSize={14}
                        fontFamily="Inter"
                      />
                    </YAxis>
                    <Tooltip cursor={{ strokeDasharray: "3 3" }} />
                    <Scatter data={chartData} fill="#01E05B" />
                  </ScatterChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

export default Charts;
