import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { ReactSVG } from "react-svg";
import Tick from "../Assets/images/check.svg";
import Horseimg from "../Assets/images/horseimg.png";
import { plan, subscriptionPlan } from "../Services/AppService";
import ReactLoading from "react-loading";

function Ourplan({ setDataPlan, dataPlan, bannerImageUrl }) {
  const [fetchPlans, setFetchPlans] = useState();
  const [isLoading, setIsLoading] = useState(true);
  // console.log("Imageegegeee",dataPlan?.saving_plans_background_banner_image_url);

  const metaData = fetchPlans?.feature?.map((item) => item?.metadata);
  console.log(metaData, "kkkkkkk")

  const fetchSubscriptionDetails = async () => {
    try {
      const contentResponse = await subscriptionPlan();
      setDataPlan(contentResponse?.data);
    } catch (error) {
      console.error("Error fetching content subscriptions:", error);
    }

    try {
      const fetchPlansDescription = await plan();
      setFetchPlans(fetchPlansDescription?.data);
      console.log(fetchPlansDescription, "fetchPlansDescription")
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    } catch (error) {
      console.error("Error fetching subscriptiion plans:", error);
    }
  };

  useEffect(() => {
    fetchSubscriptionDetails();
  }, []);

  const originalString = dataPlan?.saving_plans?.heading.toString();

  localStorage.setItem("price_id", fetchPlans?.plans?.data[0]?.id);

  // StripeApi

  // useEffect(() => {
  //   const fetchPricingDetails = async () => {
  //     const allPlans = {};

  //     try {
  //       const response = await fetch(
  //         `https://api.stripe.com/v1/prices?product=${productId}`,
  //         {
  //           method: "GET",
  //           headers: {
  //             Authorization: `Bearer ${secretKey}`,
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       );

  //       if (response.ok) {
  //         const pricingData = await response.json();

  //         const productResponse = await fetch(
  //           `https://api.stripe.com/v1/products/${.}`,
  //           {
  //             method: "GET",
  //             headers: {
  //               Authorization: `Bearer ${secretKey}`,
  //               "Content-Type": "application/json",
  //             },
  //           }
  //         );

  //         if (productResponse.ok) {
  //           const productData = await productResponse.json();

  //           const extractedFeatures = productData.features;
  //           const combinedDetails = pricingData.data.map((plan) => ({
  //             ...plan,
  //             productName: productData.name,
  //             productDescription: productData.description,
  //             productNote: productData.metadata.Note,
  //             productHeading: productData.metadata.heading,
  //             productSubHeading: productData.metadata.subHeading,
  //           }));

  //           setProductFeatues(extractedFeatures);

  //           combinedDetails.forEach((planDetail) => {
  //             allPlans["plan_details"] = planDetail;
  //           });
  //         } else {
  //           console.error(
  //             "Failed to fetch product details:",
  //             productResponse.status,
  //             productResponse.statusText
  //           );
  //         }
  //       } else {
  //         console.error(
  //           "Failed to fetch prices:",
  //           response.status,
  //           response.statusText
  //         );
  //       }

  //       setPlanDetails(allPlans);
  //     } catch (error) {
  //       console.error("Error fetching details", error);
  //     }
  //   };

  //   fetchPricingDetails();
  // }, [secretKey, productId]);

  console.log(fetchPlans, "fetchPlans");

  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}>
          <ReactLoading type={"spin"} color={"green"} height={50} width={50} />
        </div>
      ) : (
        <section
          className="pricing-plan-sec"
          style={{
            background: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${dataPlan?.saving_plans_background_banner_image_url}) no-repeat center/cover`,
          }}>

          <div className="inner-pricing-plan">
            <Container>
              <div className="row">
                <div className="col-md-4">
                  <div className="our-price">
                    <h5 className="mb-0">{dataPlan?.pricing?.heading}</h5>
                    <p className="mb-0">{dataPlan?.pricing?.sub_heading}</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="save-more">
                    <div className="inner-save-texts">
                      <p className="save-more-head mb-0">
                        {originalString?.substring(
                          0,
                          originalString?.indexOf("<span>")
                        )}
                      </p>
                      <h5 className="mb-0">
                        {originalString?.match(/<span>(.*?)<\/span>/)[1]}
                      </h5>
                      <p className="text-save-more mb-0">
                        {dataPlan?.saving_plans?.description}
                      </p>
                    </div>
                    <div className="img-horse">
                      <figure className="mb-0">
                        <img
                          className="img-fluid"
                          src={dataPlan?.goodplans_background_image_url}
                          alt=""
                        />
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="our-suscrib">
                    <p className="mb-0 dollar-price">
                      {`$${(
                        fetchPlans?.plans?.data[0]?.unit_amount / 100
                      ).toFixed(2)}`}
                    </p>
                    <h5 className="mb-0">
                      {metaData?.map((item, idx) => item?.heading)}
                    </h5>

                    <div className="inner-ul-li">
                      <p className="mb-0 heading-list-dollar">
                        {metaData?.map((item, idx) => item?.subHeading)}
                      </p>
                      <ul className="list-dollar mb-0">
                        {fetchPlans?.feature?.map((item, i) =>
                          item.features.map((items, idx) => (
                            <li key={idx}>
                              <ReactSVG className="tick-svg" src={Tick} />
                              {items?.name}
                            </li>
                          ))
                        )}
                      </ul>
                    </div>
                    <div className="note-susb">
                      <p className="node-head mb-0">Note:</p>
                      <p className="text-note">
                        {metaData?.map((item, idx) => (
                          <div key={idx}>
                            {Object.entries(item).map(([key, value]) => (
                              <p key={key}>
                                {value}
                              </p>
                            ))}
                          </div>
                        ))}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </section>
      )}
      {/* {!isLoading && !bannerImageUrl && (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <p>Failed to load banner image.</p>
          <p>Please check if the image URL is correct and accessible.</p>
        </div>
      )} */}
    </>
  );
}

export default Ourplan;
