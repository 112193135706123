import axios from "axios";
import {
  logIn,
  forgotPassword,
  register,
  resetPassword,
  verifyEmail,
  BASE_URL,
  changePassword,
  updateProfile,
  myAccount,
  homeContent,
  stallionsContent,
  stallionsDetails,
  stallionsChart,
  subscription,
  plans,
  paymentURL,
  saveSubscription,
  subscriptionList,
  policies,
  reports,
  reportSubscription,
  reportSave,
  reportList
} from "./Api";

export const logInUser = async (params) => {
  try {
    const response = await axios.post(`${BASE_URL}${logIn}`, params);
    return response.data;
  } catch (error) {
    console.error("Login error:", error);
    throw error;
  }
};

export const signUpUser = async (params) => {
  try {
    const response = await axios.post(`${BASE_URL}${register}`, params);
    return response.data;
  } catch (error) {
    console.error("SignUp error:", error);
    throw error;
  }
};

export const forgotPwd = async (params) => {
  try {
    const response = await axios.post(`${BASE_URL}${forgotPassword}`, params);
    return response.data;
  } catch (error) {
    console.error("Forgot password error");
    throw error;
  }
};

export const resetPwd = async (params, resetToken) => {
  try {
    const response = await axios.post(`${BASE_URL}${resetPassword}`, params, {
      headers: {
        token: resetToken,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Reset password error");
    throw error;
  }
};

export const verifyMail = async (params) => {
  try {
    const response = await axios.post(`${BASE_URL}${verifyEmail}`, params);
    return response.data;
  } catch (error) {
    console.error("Verify email error");
    throw error;
  }
};

export const changePass = async (params, token) => {
  try {
    const response = await axios.post(`${BASE_URL}${changePassword}`, params, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Change password error");
    throw error;
  }
};

export const updateProfileUser = async (params, token) => {
  try {
    const response = await axios.post(`${BASE_URL}${updateProfile}`, params, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Update Profile User");
    throw error;
  }
};

export const userAccount = async (token) => {
  try {
    const response = await axios.get(`${BASE_URL}${myAccount}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("User Account");
    throw error;
  }
};

export const contentHome = async () => {
  try {
    const response = await axios.get(`${BASE_URL}${homeContent}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching home content:", error);
    throw error;
  }
};

export const contentStallions = async (params) => {
  try {
    const response = await axios.get(
      `${BASE_URL}${stallionsContent}?sort_by=${params}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching content stallions:", error);
    throw error;
  }
};

export const detailStallions = async (params) => {
  try {
    const response = await axios.get(
      `${BASE_URL}${stallionsDetails}?stallion_id=${params}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching content stallions:", error);
    throw error;
  }
};

export const stallionChart = async (params) => {
  try {
    const fetchRespnse = await axios.post(
      `${BASE_URL}${stallionsChart}`,params
    );
    return fetchRespnse.data;
  } catch (error) {
    console.error("Error fetching chart details for stallions:", error);
    throw error;
  }
};

export const subscriptionPlan = async () => {
  try {
    const fetchRespnse = await axios.get(`${BASE_URL}${subscription}`);
    return fetchRespnse.data;
  } catch (error) {
    console.error("Error fetching subscription details:", error);
    throw error;
  }
};

export const plan = async () => {
  try {
    const fetchRespnse = await axios.get(`${BASE_URL}${plans}`);
    return fetchRespnse.data;
  } catch (error) {
    console.error("Error fetching subscriptions plan", error);
    throw error;
  }
};

export const paymentUrl = async (params, stallion_id, token) => {
  try {
    const fetchResponse = await axios.get(
      `${BASE_URL}${paymentURL}?price_id=${params}&stallion_id=${stallion_id}`,
      {
        headers: {
          Authorization: `Bearer${token}`,
        },
      }
    );
    return fetchResponse.data;
  } catch (error) {
    console.log("Error fetching payment plan", error);
    throw error;
  }
};

export const subscriptionStatus = async ( params, token) => {
  try {
    const fetchResponse = await axios.post(
      `${BASE_URL}${saveSubscription}`,
      params,
      {
        headers: {
          Authorization: `Bearer ${token}`
        },
      }
    );
    return fetchResponse.data;
  } catch (error) {
    console.log("Error while saving subscription", error);
    throw error;
  }
};

export const listSubscription = async(token) => {
  try{
    const fetchResponse = await axios.get(`${BASE_URL}${subscriptionList}`,{
      headers : {
        Authorization : `Bearer ${token}`
      }
    })
    return fetchResponse.data;
  }catch(error){
    console.error("Error in fetching list", error)
    throw error;
  }
}

export const policy = async() => {
  try{
    const fetchResponse = await axios.get(`${BASE_URL}${policies}`)
    return fetchResponse.data;
  }catch(error){
    console.error("Error in fetching policies", error)
    throw error;
  }
}

export const sampleReports = async() => {
  try{
    const fetchResponse = await axios.get(`${BASE_URL}${reports}`)
    return fetchResponse.data;
  }catch(error){
    console.error("Error in fetching excel sheet", error)
    throw error;
  }
}

export const reportSubscriptionUrl = async(report_id,token) => {
  try{
    const fetchResponse = await axios.get(`${BASE_URL}${reportSubscription}?report_id=${report_id}`,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return fetchResponse.data;
  }catch(error){
    console.error("Error in fetching report payment url", error)
    throw error;
  }
}

export const reportSaveReport = async(params,token)=>{
  try{
    const fetchResponse = await axios.post(`${BASE_URL}${reportSave}`,params,{
      headers : {
        Authorization: `Bearer ${token}`
      }
    })
    return fetchResponse.data;
  }catch(error){
    console.error("Error in saving reports", error)
    throw error;
  }
}

export const reportSaveList = async(token)=>{
  try{
    const fetchResponse = await axios.get(`${BASE_URL}${reportList}`,{
      headers : {
        Authorization : `Bearer ${token}`
      }
    })
    return fetchResponse.data;
  }catch(error){
    console.error("Error in showing reports", error)
    throw error;
  }
}

