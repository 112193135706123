import React from "react";
import Container from "react-bootstrap/Container";

const OurFeaturs = ({ statsDesc,bannerImageUrl }) => {


  return (
    <>
<section className="pricing-plan-sec" 
          style={{background: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${bannerImageUrl}) no-repeat center/cover`}}
> 
  
        <div className="inner-pricing-plan">
          <Container>
            <div className="row">
              {
                statsDesc?.map((item, idx)=> (
                  <div className="col-md-4" key={idx}>
                  <div className="our-features">
                    <h5 className="mb-0 our-features-heading">{item?.title}</h5>
                    <p className="text" dangerouslySetInnerHTML={{__html: item?.description}} />
                  </div>
                </div>
                ))
              }
            </div>
          </Container>
        </div>
      </section>
    </>
  );
};

export default OurFeaturs;
