export const BASE_URL = "https://www.myequisource.com/api/wp-json/v1"

export const logIn = "/login";
export const forgotPassword = "/forgot-password";
export const register = "/register";
export const resetPassword = "/reset-password";
export const verifyEmail = "/verify-email";
export const changePassword = "/change-password";
export const updateProfile = "/update-profile";
export const myAccount = "/my-account";

export const homeContent = "/content/home";
export const stallionsContent = "/content/stallions";
export const stallionsDetails = "/content/stallion-detail";
export const stallionsChart = "/content/stallion-chart-detail";
export const subscription = "/content/subscription";
export const policies = "/content/policies";

export const plans = "/stripe/plans";
export const paymentURL = "/stripe/paymenturl";
export const saveSubscription = "/stripe/subscription/save";
export const subscriptionList = "/stripe/subscription/list";
export const reportSubscription = "/stripe/checkouturl";
export const reportSave = "/stripe/checkout/save";

export const reports = "/content/reports";
export const reportList = "/user/reports";