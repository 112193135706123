import React, { useState, useEffect } from "react";
import Header from "../Components/Header";
import { useNavigate } from "react-router-dom";
import Footer from "../Components/Footer";
import Container from "react-bootstrap/Container";
import { sampleReports, reportSubscriptionUrl } from "../Services/AppService";
import { toast } from "react-toastify";
import ReactLoading from "react-loading";
import { Alert } from "bootstrap";


function Reports() {
  const [reportsData, setReportsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState([]);

  const navigate = useNavigate();

  const fetchSampleReports = async () => {
    try {
      const fetchReportsData = await sampleReports();
      setReportsData(fetchReportsData.data);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    } catch (error) {
      console.error("Error during API request:", error);

      if (error.response && error.response.data) {
        const errorMessage =
          error.response.data.message ||
          "An error occurred while downloading file";
        toast.error(errorMessage);
      } else {
        toast.error("An error occurred while downloading file");
      }
    }
  };

  const token = localStorage.getItem("equi_token");

  useEffect(() => {
    fetchSampleReports();
  }, []);

  useEffect(() => {
    setItems(reportsData.map(() => ({ showFullDescription: false }))); // Update items when reportsData changes
  }, [reportsData]);

  const handleReportSample = (e, item) => {
    e.preventDefault();

    if (item.report_url != false) {
      // token ? window.open(item?.report_url, "_blank") : navigate("/login");
      token ? window.open(item?.report_url, "_blank") : window.open(item?.report_url, "_blank");
    }
    else {
      alert('No PDF found')
    }
  };

  const handleBuyReport = (e, item) => {
    e.preventDefault();
    if (token) {
      const fetchPaymentUrl = async () => {
        try {
          const fetchResponse = await reportSubscriptionUrl(item?.id, token);
          if (fetchResponse.code === 200) {
            // window.open(fetchResponse.data.url);
            window.location.href = fetchResponse.data.url;

          } else {
            toast.error(fetchResponse.message);
          }
        } catch (error) {
          console.error("Error during API request:", error);

          if (error.response && error.response.data) {
            const errorMessage =
              error.response.data.message || "An error occurred!!";
            toast.error(errorMessage);
          } else {
            toast.error("An error occurred!!");
          }
        }
      };

      fetchPaymentUrl();
    } else {
      navigate("/login");
    }
  };

  const handleToggleDescription = (idx) => {
    setItems(
      items.map((i, index) => {
        if (index === idx) {
          return { ...i, showFullDescription: !i.showFullDescription };
        }
        return i;
      })
    );
  };

  return (
    <>
      <Header />
      {isLoading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}>
          <ReactLoading type={"spin"} color={"green"} height={50} width={50} />
        </div>
      ) : (
        <section>
          <Container>
            <div className="inner-pdf-pages">
              <div className="row g-4">
                {reportsData?.map((item, idx) => {

                  const description = item?.description;
                  const truncatedDescription = description.substring(0, 200);
                  const fullDescription = description;
                  return (
                    <div className="col-md-5" key={idx}>
                      <div className="inner-card-pdf">
                        <div className="title-main">
                          <p className="report-title">{item?.title}</p>
                        </div>
                        <div className="upper-img-body d-flex justify-content-between">
                          <div className="col-md-4">
                            <div className="upper-img-wid-clas">
                              <figure>
                                <img
                                  src={item?.report_image}
                                  className="img-fluid"
                                  alt=""
                                />
                              </figure>
                            </div>
                          </div>
                          <div className="col-md-8">
                            <div className="text-detail-horse">
                              <div className="discrip-cardpdf">
                                {/* <p
                                  className="disc-text-dpf"
                                  dangerouslySetInnerHTML={{
                                    __html: item?.description,
                                  }}
                                /> */}
                                {items[idx]?.showFullDescription ? (
                                  <p
                                    className="disc-text-dpf"
                                    dangerouslySetInnerHTML={{
                                      __html: fullDescription,
                                    }}
                                  />
                                ) : (
                                  <p
                                    className="disc-text-dpf"
                                    dangerouslySetInnerHTML={{
                                      __html: truncatedDescription,
                                    }}
                                  />
                                )}
                                <a href="javascript:void(0)"
                                  className="read-more-btn"
                                  onClick={() => handleToggleDescription(idx)}
                                >
                                  {items[idx]?.showFullDescription ? "Read Less" : "Read More"}
                                </a>
                                <p className="price-pdf-main">$ {item?.price}</p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="buttons-bye">
                          <button
                            className="buy-btn"
                            onClick={(e) => handleBuyReport(e, item)}>
                            Buy
                          </button>
                          <button
                            className="view-btn"
                            onClick={(e) => handleReportSample(e, item)}>
                            View Sample Report
                          </button>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </Container>
        </section>
      )}

      <Footer />
    </>
  );
}

export default Reports;
