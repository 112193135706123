import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Container from "react-bootstrap/Container";
import { signUpUser } from "../Services/AppService";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";

function Signup() {
  const [isLoading, setIsLoading] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  const onSubmit = async (data, e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const postSignUpData = await signUpUser(data);

      if (postSignUpData.code === 200) {
        toast.success(postSignUpData.message);
        localStorage.setItem("userDetails", data.name);
        setTimeout(() => {
          setIsLoading(false);
        }, 3000);
        navigate("/login");
      }
    } catch (error) {
      console.error("Error during API request:", error);

      if (error.response && error.response.data) {
        const errorMessage =
          error.response.data.message || "An error occurred while signing up";
        toast.error(errorMessage);
        setTimeout(() => {
          setIsLoading(false);
        }, 3000);
      } else {
        toast.error("An error occurred while signing up");
        setTimeout(() => {
          setIsLoading(false);
        }, 3000);
      }
    }
  };

  return (
    <>
      <Header />
      <div className="signup-upper">
        <Container>
          <div className="upper-signup-clas">
            <div className="inner-sgnup">
              <h5>Create My Account</h5>
              <p className="alredy-signup">
                Already have an account? <Link to="/login">Sign-In</Link>
              </p>

              <div className="signup-form">
                <Form className="" onSubmit={handleSubmit(onSubmit)}>
                  <Col>
                    <Form.Group
                      className="input-signup-main"
                      controlId="formGroupname">
                      <Form.Label>Full Name *</Form.Label>
                      <Form.Control
                        type="name"
                        placeholder="Enter full name"
                        {...register("name", {
                          required: "Name is required",
                          minLength: {
                            value: 3,
                            message: "Name must be at least 3 characters long",
                          },
                          pattern: {
                            value: /^[^\s].+[^\s]$/,
                            message: "Name cannot start or end with spaces",
                          },
                          validate: {
                            noSpecialChars: (value) => {
                              const specialCharsRegex =
                                /[!@#$%^&*()_+,\-.;'"\\/]/;
                              return (
                                !specialCharsRegex.test(value) ||
                                "Name cannot contain special characters"
                              );
                            },

                            noNumbers: (value) => {
                              const numbersRegex = /\d/;
                              return (
                                !numbersRegex.test(value) ||
                                "Name cannot contain numbers"
                              );
                            },
                          },
                        })}
                      />
                      <Form.Text className="text-danger">
                        {errors.name?.message}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                  <Form.Group
                    className="input-signup-main"
                    controlId="formGroupname">
                    <Form.Label>Phone Number *</Form.Label>
                    <Form.Control
                      type="tel"
                      placeholder="Enter Phone Number"
                      {...register("phone", {
                        required: "Phone is required",
                        pattern: {
                          value: /^[0-9]{10}$/,
                          message: "Please enter a valid 10-digit phone number",
                        },
                      })}
                      maxLength={10}
                      onKeyDown={(e) => {
                        const allowedKeys = [
                          "Backspace",
                          "ArrowLeft",
                          "ArrowRight",
                          "Delete",
                        ];
                        if (
                          !/^\d$/.test(e.key) &&
                          !allowedKeys.includes(e.key)
                        ) {
                          e.preventDefault();
                        }
                      }}
                    />
                    <Form.Text className="text-danger">
                      {errors.phone?.message}
                    </Form.Text>
                  </Form.Group>
                  <Form.Group
                    className="input-signup-main"
                    controlId="formGroupEmail">
                    <Form.Label>Email Address *</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter your Email Address"
                      {...register("email", {
                        required: "Email is required",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Invalid email address",
                        },
                      })}
                    />
                    <Form.Text className="text-danger">
                      {errors.email?.message}
                    </Form.Text>
                  </Form.Group>

                  <Form.Group
                    className="input-signup-main"
                    controlId="formGroupPassword">
                    <Form.Label>Password *</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Enter Your Password"
                      {...register("password", {
                        required: "Password is required",
                        minLength: {
                          value: 8,
                          message:
                            "Password must be at least 8 characters long",
                        },
                        maxLength: {
                          value: 20,
                          message: "Password cannot exceed 20 characters",
                        },
                        pattern: {
                          value: /^(?=.*[a-z])/,
                          message: "At least one lowercase letter is required",
                        },
                        pattern: {
                          value: /^(?=.*[A-Z])/,
                          message: "At least one uppercase letter is required",
                        },
                        pattern: {
                          value: /^(?=.*\d)/,
                          message: "At least one number is required",
                        },
                        pattern: {
                          value: /^(?=.*[@$!%*?&])/,
                          message: "At least one special character is required",
                        },
                      })}
                    />
                    <Form.Text className="text-danger">
                      {errors.password?.message}
                    </Form.Text>
                  </Form.Group>
                  <Form.Group
                    className="input-signup-main"
                    controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder="Enter your Address"
                      rows={3}
                    />
                  </Form.Group>

                  {!isLoading ? (
                    <Button className="btn-create-acc" type="submit">
                      Create My Account
                    </Button>
                  ) : (
                    <Button className="btn-create-acc-loader" type="submit">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "start",
                          justifyContent: "center",
                          marginBottom: "2px",
                        }}>
                        <ReactLoading
                          type={"spin"}
                          color={"#fff"}
                          height={20}
                          width={25}
                        />
                      </div>
                    </Button>
                  )}
                </Form>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default Signup;
