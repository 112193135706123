import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";

const Terms = () => {
  return (
    <>
      <Header />
      <div className="terms-container">
        <h1>Terms and Conditions</h1>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam id
          lorem ut ipsum vestibulum ultricies. Sed gravida, lectus at fermentum
          eleifend, ipsum nisi vestibulum purus, at tempor ex nisl vitae felis.
        </p>
        <p>
          Mauris interdum dignissim dolor, a placerat libero pulvinar a. Integer
          vel malesuada odio. Nunc non orci vitae orci tincidunt feugiat.
        </p>
        <p>
          Mauris interdum dignissim dolor, a placerat libero pulvinar a. Integer
          vel malesuada odio. Nunc non orci vitae orci tincidunt feugiat.
        </p>
        <p>
          Mauris interdum dignissim dolor, a placerat libero pulvinar a. Integer
          vel malesuada odio. Nunc non orci vitae orci tincidunt feugiat. Mauris
          interdum dignissim dolor, a placerat libero pulvinar a. Integer vel
          malesuada odio. Nunc non orci vitae orci tincidunt feugiat. Mauris
          interdum dignissim dolor, a placerat libero pulvinar a. Integer vel
          malesuada odio. Nunc non orci vitae orci tincidunt feugiat.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default Terms;
