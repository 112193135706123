import React, { useState, useRef, useEffect, CSSProperties } from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Tabestable from "../Components/Tabestable";
import Charts from "../Components/Charts";
import Slider from "react-slick";
import Ourplan from "../Components/Ourplan";
import { contentHome } from "../Services/AppService";
import OurFeaturs from "../Components/OurFeaturs";
import ReactLoading from "react-loading";
import { set } from "react-hook-form";
import Login from "../Screens/Login";
function Home() {
  const [isLoading, setIsLoading] = useState(true);

  const [dataPlan, setDataPlan] = useState();
  const slideNames = ["Barrel Racing", "Roping", "Reining"];
  const [stallionIndex, setStallionIndex] = useState("");
  const [selectedKey, setSelectedKey] = useState("s_index_1");
  const [colStallionIndex, setColStallionIndex] = useState([]);
  const [data, setData] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [bannerImageUrl, setBannerImageUrl] = useState("");
  const [loginImageUrl, setLoginImageUrl] = useState("");

  const sliderRef = useRef(null);

  console.log("dataplannnnn>>>>>>>>", loginImageUrl);

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    // autoplaySpeed: 2000,
    appendDots: (dots) => (
      <div style={{ textAlign: "center" }}>
        <ul style={{ listStyle: "none", padding: 0 }}>
          {dots.map((dot, index) => (
            <li
              key={index}
              className={
                dot.props.className +
                (index === currentSlide ? " active-dot" : "")
              }
              style={{
                display: "inline-block",
                margin: "0 5px",
                cursor: "pointer",
              }}
              onClick={() => {
                setCurrentSlide(index);
                sliderRef.current.slickGoTo(index);
              }}>
              <button>{index + 1}</button>
              <span> {slideNames[index]}</span>
            </li>
          ))}
        </ul>
      </div>
    ),
  };

  const statsDesc = dataPlan?.chart_content;

  const fetchResponse = async () => {
    try {
      const contentResponse = await contentHome();
      console.log(
        contentResponse.data.background_banner_image_login_url,
        "contentResponse"
      );
      console.log(
        contentResponse.data.background_banner_image_home_url,
        "Image Data>>>>"
      );

      setLoginImageUrl(contentResponse.data.background_banner_image_login_url);
      setData(contentResponse?.data?.home_banner);
      setBannerImageUrl(
        contentResponse?.data?.background_banner_image_home_url
      );
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    } catch (error) {
      console.error("Error fetching home content:", error);
    }
  };

  useEffect(() => {
    fetchResponse();
  }, []);
  const handleScrollToChart = () => {
    const chartSection = document.getElementById("chart-section");
    if (chartSection) {
      chartSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      {/* <div className="upper-main-header"> */}
      <Header />
      {/* </div> */}
      {isLoading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}>
          <ReactLoading type={"spin"} color={"green"} height={50} width={50} />
        </div>
      ) : (
        <>
          <div className="upper-slider-home">
            <Slider ref={sliderRef} {...settings}>
              {data?.map((item, index) => (
                <div className="banner-main" key={index}>
                  <div
                    className="inner-banner-main"
                    style={{
                      backgroundImage: `url(${item?.image})`,
                    }}>
                    <Container>
                      <div className="inner-bannersec">
                        <h5 className="mb-0">{item?.sub_heading}</h5>
                        <h1>{item?.heading}</h1>
                        <p className="mb-0">{item?.description}</p>
                        <Button
                          className="banner-btn"
                          onClick={handleScrollToChart}>
                          {item?.subscribe_button_text}
                        </Button>
                      </div>
                    </Container>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
          {/*----------------------------- Table sec ------------------------------------------------- */}
          <section className="grade-sec" id="gradeTopStallions">
            <Container>
              <h5 className="mb-0">Grades & Top Stallions</h5>
              {/* <p className='index-value mb-0'>Index Value</p> */}
              <Tabestable
                setStallionIndex={setStallionIndex}
                stallionIndex={stallionIndex}
                setColStallionIndex={setColStallionIndex}
                selectedKey={selectedKey}
                setSelectedKey={setSelectedKey}
              />
            </Container>
          </section>
          {/* --------------------------------chart sec -------------------------------------- */}
          <section className="chart-sec" id="chart-section">
            <Charts
              stallionIndex={stallionIndex}
              colStallionIndex={colStallionIndex}
              selectedKey={selectedKey}
            />
          </section>
          {/*---------------------------  section ---------------------------------------------- */}
          <OurFeaturs statsDesc={statsDesc} bannerImageUrl={bannerImageUrl} />
          {/*--------------------------- pricing section ---------------------------------------------- */}
          <Ourplan
            setDataPlan={setDataPlan}
            dataPlan={dataPlan}
            bannerImageUrl={bannerImageUrl}
          />
          {/*-------------------------- Footer ------------------------------------------------------- */}
        </>
      )}
      <Footer />
    </>
  );
}

export default Home;
