import React from 'react'
import { Link } from "react-router-dom";
import { Player } from '@lottiefiles/react-lottie-player';
import Lotte from '../Assets/lotifile/reject.json';

const FailurePage = () => {
  return (
    <>
    <section class="upper-success-payment">
        <div class="main-inner-cont">
            <div class="inner-success">
                <div class="lotifile">
                    <Player
                        autoplay={true}
                        loop={true}
                        controls={false}
                        src={Lotte}
                        speed={0.8}
                    >
                    </Player>
                  
                </div>
                <h4 class="payment-success-head">OOPS! Something went wrong</h4>
                <div class="payment-type">

                </div>
                <div class="btn-success-pay">
                    <Link to="/" className="btn-reject-close">
                        Close
                    </Link>
                </div>

            </div>
        </div>
    </section>
</>
  )
}

export default FailurePage