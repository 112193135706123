import React from "react";
import Tab from "react-bootstrap/Tab";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";

function Sirerankingtable({ stallionsDetails }) {
  // FIX ME: not optimized
  return (
    <>
      <div className="siretable">
        <Tab.Container id="left-tabs-example" defaultActiveKey="home">
          <Row>
            <Col sm={12}>
              <Nav variant="pills" className="grades-table sire-table-pills">
                <Nav.Item>
                  <Nav.Link eventKey="home">Sire Index</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="profile">Dams Sire Index</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="contact">2nd Dams Sire Index</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="index">3rd Dams Sire Index</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={12}>
              <Tab.Content>
                <Tab.Pane eventKey="home">
                  <div className="main-table-home">
                    <table className="w-100">
                      <thead>
                        <tr>
                          <th className="bg1">Weighted Formula</th>
                          <th className="tabl2sec">Progeny</th>
                          <th className="tabl3sec">Total Progeny</th>
                          <th className="tabl4sec">
                            Stallion Rate Variable(%)
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{stallionsDetails.s_index_1}</td>
                          <td>{stallionsDetails.s_progeny_1}</td>
                          <td>{stallionsDetails.s_progeny_t_1}</td>
                          <td>
                            {`${Math.round(
                              (stallionsDetails.s_progeny_1 /
                                stallionsDetails.s_progeny_t_1) *
                                100
                            )}%`}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="profile">
                  <div className="main-table-home">
                    <table className="w-100">
                      <thead>
                        <tr>
                          <th className="bg1">Weighted Formula</th>
                          <th className="tabl2sec">Progeny</th>
                          <th className="tabl3sec">Total Progeny</th>
                          <th className="tabl4sec">
                            Stallion Rate Variable(%)
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{stallionsDetails.s_index_2}</td>
                          <td>{stallionsDetails.s_progeny_2}</td>
                          <td>{stallionsDetails.s_progeny_t_2}</td>
                          <td>
                            {`${Math.round(
                              (stallionsDetails.s_progeny_2 /
                                stallionsDetails.s_progeny_t_2) *
                                100
                            )}%`}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="contact">
                  <div className="main-table-home">
                    <table className="w-100">
                      <thead>
                        <tr>
                          <th className="bg1">Weighted Formula</th>
                          <th className="tabl2sec">Progeny</th>
                          <th className="tabl3sec">Total Progeny</th>
                          <th className="tabl4sec">
                            Stallion Rate Variable(%)
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{stallionsDetails.s_index_3}</td>
                          <td>{stallionsDetails.s_progeny_3}</td>
                          <td>{stallionsDetails.s_progeny_t_3}</td>
                          <td>
                            {`${Math.round(
                              (stallionsDetails.s_progeny_3 /
                                stallionsDetails.s_progeny_t_3) *
                                100
                            )}%`}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="index">
                  <div className="main-table-home">
                    <table className="w-100">
                      <thead>
                        <tr>
                          <th className="bg1">Weighted Formula</th>
                          <th className="tabl2sec">Progeny</th>
                          <th className="tabl3sec">Total Progeny</th>
                          <th className="tabl4sec">
                            Stallion Rate Variable(%)
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{stallionsDetails.s_index_4}</td>
                          <td>{stallionsDetails.s_progeny_4}</td>
                          <td>{stallionsDetails.s_progeny_t_4}</td>
                          <td>
                            {`${Math.round(
                              (stallionsDetails.s_progeny_4 /
                                stallionsDetails.s_progeny_t_4) *
                                100
                            )}%`}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </>
  );
}

export default Sirerankingtable;
