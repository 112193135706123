import './App.css';
import React from 'react';
import { BrowserRouter, Routes, Route, HashRouter, Navigate } from "react-router-dom";
import Home from './Screens/Home';
import Login from './Screens/Login';
import Signup from './Screens/Signup';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from 'react-toastify';
import Subscription from './Screens/Subscription';
import Forgotpass from './Screens/Forgotpass';
import Resetpass from './Screens/Resetpass';
import Updateaccount from './Screens/Updateaccount';
import Detailpage from './Screens/Detailpage';
import TermsAndConditions from './Screens/TermsAndConditions';
import SuccessPage from "./Screens/SuccessPage";
import FailurePage from "./Screens/FailurePage";
import Reports from './Screens/Reports';
import Protected from './Screens/Protected';


function App() {

  return (
    <HashRouter>
      <ToastContainer />
      <Routes>
        <>
          <Route path='/home' element={<Home />} />
          <Route index element={<Home />} />
          <Route path='/login' element={<Login />} />
          <Route path='/signUp' element={<Signup />} />
          <Route path='/subscription' element={<Subscription />} />
          <Route path='/forgotpassword' element={<Forgotpass />} />
          <Route path='/resetpassword' element={<Resetpass />} />
          <Route path="/updateaccount" element={<Protected componenets={Updateaccount} />}/>
          <Route path='/detail/:stallionIndex' element={<Detailpage />} />
          <Route path='/termsconditions' element={<TermsAndConditions />} />
          <Route path="/success" element={<SuccessPage />} />
          <Route path="/failure" element={<FailurePage />} />
          <Route path="/reports" element={<Reports />} />
        </>
      </Routes>
    </HashRouter>
  );
}

export default App;
