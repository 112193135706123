import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { listSubscription } from "../Services/AppService";

function Subscriptionplan() {
  const [subscriptionListData, setSubscriptionListData] = useState();

  const table_headers = [
    "Sr. no.",
    "Stallion Name",
    "Subscription Date",
    "Amount",
    "Status",
  ];

  const token = localStorage.getItem("equi_token");

  const subscriptionList = async () => {
    try {
      const fetchResponse = await listSubscription(token);
      setSubscriptionListData(fetchResponse.data);

      return;
    } catch (error) {
      console.log("Error while fetching subscription list");
    }
  };

  useEffect(() => {
    subscriptionList();
  }, []);


  return (
    <>
      <div className="upper-right-sec-update">
        <h5 className="change-pass-main">Subscription Plans</h5>
        {subscriptionListData?.length > 0 ? (
          <div className="main-table-home subs-table-wrapper table-responsive">
            <table className="w-100">
              <tr>
                {table_headers.map((item,index)=>(
                    <th key={index} className={`bg${index + 1}`}>{item}</th>
                ))}
              </tr>
              {subscriptionListData.map((subscription, index) => (
                    <tr key={index}>
                        <td>{index+1}</td>
                        <td>{subscription.s_name}</td>
                        <td>{subscription.current_period_start}</td>
                        <td>{`$${subscription.amount}`}</td>
                        <td>{subscription.status}</td>
                    </tr>
                ))}
            </table>
          </div>
        ) : (
          <div className="wrapper-cgangepass">
            <p className="dont-have">Currently You Don’t Have Any Plan</p>
            <Link to= "/home">
            <Button className="change-pass-btn-main">Buy Plan</Button>
            </Link>
          </div>
        )}
      </div>
    </>
  );
}

export default Subscriptionplan;
