import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { reportSaveList } from "../Services/AppService";

const ReportPlan = () => {
  const [reportListData, setReportListData] = useState();

  const table_headers = [
    "Sr. no.",
    "Report Name",
    "Subscription Date",
    "Amount",
  ];

  const token = localStorage.getItem("equi_token");

  const reportList = async () => {
    try {
      const fetchResponse = await reportSaveList(token);
      setReportListData(fetchResponse.data);
    } catch (error) {
      console.log("Error while fetching subscription list");
    }
  };

  useEffect(() => {
    reportList();
  }, []);


  return (
    <>
      <div className="upper-right-sec-update">
        <h5 className="change-pass-main">Sample Reports</h5>
        {reportListData?.length > 0 ? (
          <div className="main-table-home subs-table-wrapper table-responsive">
            <table className="w-100">
              <tr>
                {table_headers.map((item, index) => (
                  <th key={index} className={`bg${index + 1}`}>
                    {item}
                  </th>
                ))}
              </tr>
              {reportListData?.map((subscription, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{subscription.report_name}</td>
                  <td>{subscription.pay_date}</td>
                  <td>{`$${subscription.amount}`}</td>
                </tr>
              ))}
            </table>
          </div>
        ) : (
          <div className="wrapper-cgangepass">
            <p className="dont-have">
              Currently You Don’t Have Any Report Bought
            </p>
            <Link to="/reports">
              <Button className="change-pass-btn-main">Buy Reports</Button>
            </Link>
          </div>
        )}
      </div>
    </>
  );
};

export default ReportPlan;
